import { type useCallback, useEffect, useMemo, useRef } from 'react';
import { isDeepEqual } from 'remeda';

export type UseEffectParams = Parameters<typeof useEffect>;
export type EffectDependencyList = UseEffectParams[1];
export type EffectCallback = UseEffectParams[0];
export type UseEffectReturn = ReturnType<typeof useEffect>;
export type UseCallbackParams = Parameters<typeof useCallback>;
export type CallbackDependencyList = UseCallbackParams[1];
export type CallbackCallback = UseCallbackParams[0];
export type UseCallbackReturn = ReturnType<typeof useCallback>;

function isPrimitive(value: unknown) {
  return /^[bns]/.test(typeof value);
}

function checkDependencies(dependencies: EffectDependencyList, fnName: string) {
  if (!dependencies || dependencies.length === 0) {
    throw new Error(
      `${fnName} should not be used with no dependencies. Use React.useEffect instead.`,
    );
  }
  if (dependencies.every(isPrimitive)) {
    throw new Error(
      `${fnName} should not be used with dependencies that are all primitive values. Use React.useEffect instead.`,
    );
  }
}

export function useDeepCompareMemoize<T>(value: T) {
  const ref = useRef<T>(value);
  const signalRef = useRef<number>(0);

  if (!isDeepEqual(value, ref.current)) {
    ref.current = value;
    signalRef.current += 1;
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => ref.current, [signalRef.current]);
}

export function useDeepCompareEffect(
  callback: EffectCallback,
  dependencies: EffectDependencyList,
): UseEffectReturn {
  if (process.env.NODE_ENV !== 'production') {
    checkDependencies(dependencies, 'useDeepCompareEffect');
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useEffect(callback, useDeepCompareMemoize(dependencies));
}
