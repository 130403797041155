import { waitUntil } from '@iheartradio/web.utilities';
import { createEmitter } from '@iheartradio/web.utilities/create-emitter';
import type { Logger } from '@iheartradio/web.utilities/create-logger';

import type { HeaderBiddingConfig } from '../types.js';
import type { MoatHeaderBidderMethods } from './types.js';

export const createMoatHeaderBidder = ({ logger }: { logger: Logger }) => {
  let isReady = false;

  const moat = createEmitter<MoatHeaderBidderMethods>({
    async initialize(options?: HeaderBiddingConfig) {
      const bidderEnabled = options?.enabledBidders.includes('moat') ?? false;

      if (bidderEnabled) {
        logger.info('Initializing MOAT Header Bidder');
        try {
          await waitUntil(() => !!globalThis.window.moatPrebidApi);

          isReady = true;
          logger.info('MOAT Header Bidder initialized');
        } catch (error) {
          logger.warn('Unable to initialize MOAT Header Bidder', { error });
        }
      }
    },
    async fetchBids(_slots: googletag.Slot[]) {
      if (
        isReady &&
        typeof globalThis.window?.moatPrebidApi?.pageDataAvailable ===
          'function' &&
        globalThis.window?.moatPrebidApi.pageDataAvailable()
      ) {
        return globalThis.window.moatPrebidApi?.setMoatTargetingForAllSlots();
      } else {
        logger.warn('Unable to fetch MOAT header bids');
        return false;
      }
    },
  });

  return moat;
};
