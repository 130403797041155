import { isUndefined, memoize } from '@iheartradio/web.utilities';
import { type UIMatch, useMatches } from '@remix-run/react';
import { useMemo, useRef } from 'react';

import { useUser } from '~app/contexts/user';
import { useRequestInfo } from '~app/hooks/use-request-info';
import { playback } from '~app/playback/playback';

import { type DisplayTargetingParams, DisplayTargetingKeys } from './model';

export const getRouteTargetingParams = memoize(
  (matches: UIMatch[]): DisplayTargetingParams => {
    const targetingMap = new Map<keyof DisplayTargetingParams, unknown>();

    for (const match of matches) {
      if (match.data) {
        for (const [key, value] of Object.entries(match.data)) {
          if (
            DisplayTargetingKeys.includes(key as keyof DisplayTargetingParams)
          ) {
            targetingMap.set(key as keyof DisplayTargetingParams, value);
          }
        }
      }
    }

    return Object.fromEntries(targetingMap.entries());
  },
);

export const useDisplayTargeting = (): { [k: string]: unknown } => {
  const user = useRef(useUser());

  const routeTargetingParams = getRouteTargetingParams(useMatches());
  const playerState = playback.useState();
  const { locale } = useRequestInfo();

  return useMemo(() => {
    const item = playerState.queue?.[0];

    const playedFrom = playerState.station?.context ?? 0;
    const ccrcontent2 = playerState.station?.type?.toLowerCase();
    const ccrformat = item?.meta?.format;
    const ccrmarket = item?.meta?.market;

    const {
      ccrcontent1,
      ccrcontent3,
      section,
      pageformat,
      pagemarket,
      visitNum,
    } = routeTargetingParams;

    const playlistid = routeTargetingParams['aw_0_1st.playlistid'];
    const ihmgenre = routeTargetingParams['aw_0_1st.ihmgenre'];
    const playlisttype = routeTargetingParams['aw_0_1st.playlisttype'];

    const deviceType =
      globalThis.window?.navigator?.userAgentData?.mobile ?
        'mobile'
      : 'desktop';

    const profileId = user.current?.profileId;

    const childDirected =
      ((item?.meta?.childOriented || !!item?.meta?.ageLimit) &&
        user.current?.isAnonymous) ??
      false;

    const seed =
      playerState.station && 'seed' in playerState.station ?
        playerState.station?.seed
      : undefined;

    const targetingParams: Record<
      string,
      string | boolean | undefined | number
    > = {
      // UserDisplayTargetingKeys
      childDirected,
      deviceType,
      locale,
      profileId,
      visitNum,
      // StationDisplayTargetingKeys
      'aw_0_1st.playlistid': playlistid,
      'aw_0_1st.ihmgenre': ihmgenre,
      'aw_0_1st.playlisttype': playlisttype,
      ccrcontent2,
      pageformat,
      pagemarket,
      ccrcontent3,
      ccrformat,
      ccrmarket,
      section,
      // PlaybackDisplayTargetingKeys
      playedFrom,
      provider: 'cc',
      seed,
      // GlobalDisplayTargetingKeys
      ccrcontent1,
      env: 'listen',
    };

    const filtered = Object.fromEntries(
      Object.entries(targetingParams).reduce(
        (entries, [key, value]) => {
          if (!isUndefined(value)) {
            entries.push([key, value]);
          }
          return entries;
        },
        [] as [string, unknown][],
      ),
    );
    return filtered;
  }, [playerState.station, playerState.queue, routeTargetingParams, locale]);
};
